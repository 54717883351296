import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Neue Icons für Datenschutz/Datensicherheit etc.
import {
  FaLock,
  FaUserShield,
  FaShieldAlt,
  FaHandsHelping,
  FaLanguage,
  FaMoneyBillWave,
  FaFileAlt,
  FaUserTie
} from 'react-icons/fa';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';
import { Button } from '../../components/atoms';

import './deepchat.scss';

const deepchatQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "DeepChat" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
            }
          }
          imageAlt
        }
      }
    }
    allFile(filter: { name: { regex: "/(deepseek|chat)/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const DeepChatCase = () => (
  <StaticQuery
    query={deepchatQuery}
    render={({ allStagesJson, allFile }) => {
      const stageData = allStagesJson.edges[0]?.node;
      const featuresImg = getImage(allFile.edges[0]?.node.childImageSharp);
      const supportImg = getImage(allFile.edges[1]?.node.childImageSharp);

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              gatsbyImageData: stageData?.imageSrc?.childImageSharp?.gatsbyImageData,
              alt: stageData?.imageAlt || 'DeepChat Image',
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData?.title || 'DeepChat' }} />}
          >
            <div>
              {stageData?.contentBlocks?.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
            <div className="learn-more__button">
              <Button url="/kontakt" text="Kontaktieren Sie uns" isPrimary />
            </div>
          </Stage>

          <CaseBlock
            title="DeepChat – Maximale Datensicherheit"
            subtitle="Schweizer Hosting oder On-Premises"
            graphic={featuresImg && <GatsbyImage image={featuresImg} alt="DeepChat Features" />}
          >
            <p>
              DeepChat ist unsere leistungsfähige, generative KI-Lösung, die speziell für sensible Daten
              konzipiert wurde. Ob im Schweizer Rechenzentrum oder direkt in Ihrer eigenen IT-Umgebung – 
              Sie behalten stets die volle Kontrolle über Ihre Daten.
            </p>
          </CaseBlock>

          <CaseBlock
            title="Vielseitige Einsatzmöglichkeiten"
            subtitle="Von Kundensupport bis HR"
            graphic={supportImg && <GatsbyImage image={supportImg} alt="DeepChat Support und Integration" />}
          >
            <p>
              Nutzen Sie DeepChat, um wiederkehrende Anfragen vollautomatisch zu beantworten und 
              interne Prozesse zu beschleunigen. Ob Kundensupport, HR-Anliegen oder komplexe 
              Dokumenten-Recherchen – unser System kann Ihre spezifischen Unternehmensdaten 
              integrieren, ohne dass sensible Informationen das gesicherte Umfeld verlassen.
            </p>
          </CaseBlock>

          <div className="container">
            <Facts title="Wichtige Funktionen">
              <ul>
                <li>Hosting in Schweizer Datencentern oder On-Premises</li>
                <li>Konzipiert für sensible Daten: Healthcare, Finanzen, Behörden u. v. m.</li>
                <li>Beliebige Unternehmensdaten integrierbar (z. B. FAQs, HR-Dokumente)</li>
                <li>Mehrsprachige Dialoge für globalen Einsatz</li>
                <li>Nahtlose Anbindung an CRM, Ticketing und andere IT-Systeme</li>
                <li>Datenschutzkonform nach revDSG</li>
              </ul>
            </Facts>
          </div>

          <section className="feature-section container">
            <h2>Die wichtigsten Vorteile von DeepChat</h2>
            <div className="features-grid">
              <div className="feature">
                <FaLock className="feature-icon" />
                <h3>Höchste Sicherheit</h3>
                <p>Verschlüsselte Kommunikation und klar definierte Zugriffsrechte gewährleisten ein sicheres Umfeld für Ihre Daten.</p>
              </div>
              <div className="feature">
                <FaShieldAlt className="feature-icon" />
                <h3>DSG- und DSGVO-konform</h3>
                <p>DeepChat erfüllt strenge Datenschutzrichtlinien und ist perfekt für sensible Bereiche wie Versicherungen oder Behörden.</p>
              </div>
              <div className="feature">
                <FaUserShield className="feature-icon" />
                <h3>Sensible Anfragen abdecken</h3>
                <p>Ob HR-Fragen oder Kundendaten – DeepChat geht sicher mit vertraulichen Informationen um.</p>
              </div>
              <div className="feature">
                <FaFileAlt className="feature-icon" />
                <h3>Interne Dokumente</h3>
                <p>Laden Sie Ihre Dokumente hoch, lassen Sie Inhalte analysieren und automatisieren Sie Recherchen in Echtzeit.</p>
              </div>
              <div className="feature">
                <FaHandsHelping className="feature-icon" />
                <h3>Entlastung für Ihr Team</h3>
                <p>DeepChat übernimmt 80–90% aller repetitiven Aufgaben, damit sich Ihre Mitarbeiter auf komplexere Fälle konzentrieren können.</p>
              </div>
              <div className="feature">
                <FaLanguage className="feature-icon" />
                <h3>Mehrsprachige Kommunikation</h3>
                <p>Kommunizieren Sie in verschiedenen Sprachen – ideal für internationale Standorte und multilinguale Kunden.</p>
              </div>
              <div className="feature">
                <FaUserTie className="feature-icon" />
                <h3>HR-Assistent</h3>
                <p>Bearbeitet Anfragen zu Urlaubsregelungen, Benefits oder Onboarding-Prozessen – sensibel und stets datenschutzkonform.</p>
              </div>
              <div className="feature">
                <FaMoneyBillWave className="feature-icon" />
                <h3>Kosteneffizient</h3>
                <p>Sparen Sie Ressourcen im Support und in der Datenpflege, ohne auf Qualität zu verzichten.</p>
              </div>
            </div>
          </section>
        </DefaultLayout>
      );
    }}
  />
);

export default DeepChatCase;
